
import { Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import MovementList from "@/components/user/list/MovementList.vue";
import { IMovementCsvRequest } from "@/types/movement";
@Component({
  components: { MovementList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // csv
  @Action("csv/userMovementExport")
  public getMovementCsv!: (request: IMovementCsvRequest) => Promise<boolean>;

  //パラメータ定義
  public csv_params: IMovementCsvRequest = {
    status: 1,
    search_span_start: "",
    search_span_end: ""
  };

  //変数定義
  public dialog = false;
  public valid = true;
  public menu = {
    start: false,
    end: false
  };

  // methods
  // ダウンロードボタンを押した時の挙動
  public async download_btn() {
    await this.getMovementCsv(this.csv_params);
    this.dialog = false;
  }

  // モーダルの閉じるボタンを押した時の挙動
  public close_btn() {
    this.dialog = false;
    this.csv_params.search_span_start = "";
    this.csv_params.search_span_end = "";
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.csv_params.search_span_end ||
      !this.csv_params.search_span_start ||
      this.csv_params.search_span_end >= this.csv_params.search_span_start
    ) {
      return true;
    }
    return "正しい日付を入力してください";
  }
}
